import React from "react"

export default function Informasjon() {
    return (
        <div>
            <div id="informasjon" className="page">
                <h1 className="topHeader">INFORMASJON</h1>
            </div>
            <div className="underPage">
                <div>
                    <h2>Praktisk</h2>
                    <p>
                        <strong>Adresse:</strong>{" "}
                        <a
                            href={
                                "https://www.google.com/maps/place/36%C2%B045'53.7%22N+3%C2%B051'53.8%22W/@36.7649181,-3.8655871,210m/data=!3m2!1e3!4b1!4m13!1m8!3m7!1s0xd72245578547955:0xc0b18f1001c6ef12!2sC.+Mariposa,+21,+29780+San+Juan+de+Capistrano,+M%C3%A1laga,+Spania!3b1!8m2!3d36.7649971!4d-3.8650332!16s%2Fg%2F11cpj_pv9x!3m3!8m2!3d36.764917!4d-3.864942?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D"
                            }
                        >
                            C. Mariposa, 21, 29780 San Juan de Capistrano,
                            Málaga, Spania
                        </a>
                    </p>
                    <p>
                        <strong>Whatsapp Miguel:</strong> XXX
                    </p>

                    <h2>Leie</h2>
                    <strong>For å leie må du:</strong>
                    <ol>
                        <li>Finne ledig tid i den felles kalenderen</li>
                        <li>Legge inn perioden du ønsker med rød farge</li>
                        <li>Sende en forespørsel til Viggo, Siv og Mikkel</li>
                        <li>
                            Markere perioden smom grønn når du har fått godkjent
                            av alle tre
                        </li>
                    </ol>
                    <h3>Priser</h3>
                    <p>
                        <strong>Hus (kun oppe):</strong> 2000 kroner per uke
                    </p>
                    <p>
                        <strong>Hybel nede:</strong> 1500 kroner per uke
                    </p>
                </div>
            </div>
        </div>
    )
}
